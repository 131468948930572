@import url("https://fonts.googleapis.com/css2?family=Allan&display=swap");

.marquee-fleet {
  background-color: #141414;
  padding: 20px 0; /* Optional: Adds padding above and below the text */
  width: 90vw;
  margin: 20px auto;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 0;
  }

.marquee-fleet h1{
  font-family: "Alumni Sans", sans-serif;
  font-size: 3vw;
  font-weight: 100;
  margin: 0 20px;
  color: white;
}

/* ... existing styles ... */

.fleet-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  font-family: "Alumni Sans", sans-serif;
  margin-top: 50px;
  overflow-x: clip;
}

.fleet-heading::before,
.fleet-heading::after {
  content: '';
  position: absolute;
  top: 50%; /* Align vertically at the center of the div */
  width: 40vw; /* Length of the line */
  height: 2px; /* Thickness of the line */
  background-color: #333; /* Color of the line */
}

.fleet-heading::before {
  left: 0;
  margin-left: -21vw; /* Adjust based on the length and position of the line */
}

.fleet-heading::after {
  right: 0;
  margin-right: -30vw; /* Adjust based on the length and position of the line */
}

.fleet-heading-col {
  flex: 1; /* Equal width for both columns */
  padding: 20px; /* Adjust padding as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fleet-heading-col:last-child {
  align-items: flex-start; /* Aligns content of the first column to the left */
}

.fleet-heading-col:first-child {
  align-items: flex-end; /* Aligns content of the last column to the right */
  order: -1; /* Moves this column to the left */
}
.fleet-heading h2 {
  font-size: 6vw;
  font-weight: 200;
  margin: 0 20px;
  color: #141414;
}

.fleet-heading p {
  font-weight: 300;
  max-width: 70%; /* Smaller width for the paragraph */
  text-align: left; /* Aligns text to the left */
}

/* ... other styles ... */


/* ... other styles ... */



.fleet-home {
  color: #141414;
  padding-left: 10vw; /* Space on the left */
  overflow-x: clip !important; /* Hide horizontal scrollbar */
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative; /* Required for positioning the pseudo-element */
  font-family: "Alumni Sans", sans-serif;
  font-size: 1.5em;
}

.fleet-owl-carousel::after {
  content: '';
  position: absolute;
  top: 0;
  width: 7%;
  height: 100%;
  background: linear-gradient(to right, rgb(251, 251, 251), rgba(255, 255, 255, 0));
  z-index: 5; /* Ensures the overlay is above the content */
}

/* Ensure content is accessible and visible */
.fleet-home * {
  position: relative;
  z-index: 2;
}

.fleet-home h4{
  margin: 10px 0 5px 0;
}

.card-fleet-home {
  background: #E5EAEC;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.item-fleet-home-link   {
text-decoration: none;
color: #141414;
}


.item-fleet-home {
  height: 100%; /* Ensure it fills the slide vertically */
  margin-left: 5vw;
  cursor: pointer;
  transition: all 0.3s ease; 
}


.item-fleet-home:hover {
  transform: scale(1.05); /* Slightly increase size */
  margin-bottom: 30px;
}
.carousel-image-fleet-home {
  max-width: 100%; /* Maximum width is the width of the card */
  object-fit: cover; /* Maintain aspect ratio */
}


.fleet-home .slick-slide {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap !important;
}

.owl-stage{
  padding-top: 70px;

}

/* Custom CSS for Owl Carousel Arrows */
.owl-theme .owl-nav {
  position: absolute;
  top: -50px; /* Adjust this value as needed */
  right: 4vw; /* Adjust to align the arrows to the right */
  width: 100px; /* This width ensures both buttons are on the right */

}

/* Custom CSS for Owl Carousel Arrows */
.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
  position: absolute;
  height: 34px;
  width: 34px;
  margin: 0;
  background: #fff;
  color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
}
.service-carousel .owl-nav [class*='owl-']{
  background: none !important;
  border-radius: 100px !important;
  z-index: 100;
}

.owl-theme .owl-nav .owl-prev {
  left: 0;
  content: '';
  z-index: 100;
}

.owl-theme .owl-nav .owl-next {
  right: 0;
  content: '';
  z-index: 100;
}

/* Font Awesome Arrow Icons */
.owl-theme .owl-nav .owl-prev:before,
.owl-theme .owl-nav .owl-next:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: '\f053'; /* Unicode for Font Awesome left arrow */
  display: block;
}

.owl-theme .owl-nav .owl-next:before {
  content: '\f054'; /* Unicode for Font Awesome right arrow */
}

.owl-fleet-home .owl-nav [class*='owl-']{
  color: #000  !important;
  background: none !important;
  border-radius: 100px !important;
  z-index: 100;
}


/* ... (existing styles) ... */

.carousel-controls {
  position: relative;
}

.carousel-categories {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 264px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-categories li {
  margin-right: 20px; /* Adjust spacing between links */
  border-right: 1px solid lightgrey; /* Light grey line between items */
  padding-right: 20px; /* Padding to the right of the text */
}

.carousel-categories li:last-child {
  border-right: none; /* No border for the last item */
}

.carousel-categories a {
  text-decoration: none;
  color: #333; /* Link color */
  font-weight: bold;
  border-radius: 10px;
  padding: 5px 10px; /* Padding for better click area */
}

.carousel-categories a:hover,
.carousel-categories a:active,
.carousel-categories a.selected { /* Add 'selected' class for the active item */
  background-color: #e63946;

  color: white;
}

/* Rest of your styles... */



/* ... existing styles ... */

.info-box {
  display: inline-flex;
  align-items: center;
  background-color: #E5EAEC; /* Box background color */
  border-radius: 10px; /* Rounded corners */
  padding: 5px 10px; /* Padding inside the box */
  margin-right: 10px; /* Space between boxes */
  font-size: 14px; /* Font size for the number */
}

.info-box span {
  margin-left: 5px; /* Space between icon and number */
}

/* ... other styles ... */


.fleet-owl-carousel .owl-stage {
  margin-left: -50px; /* Counteract the left padding */
}

@media screen and (max-width: 999px) {
  /* Adjusting marquee and fleet heading for smaller screens */
  .marquee-fleet {
    padding: 20px 0;
    width: 95vw;
  }

  .marquee-fleet h1 {
    font-size: 1.8em;
  }

  .fleet-heading {
    flex-direction: column;
    text-align: center;
  }

  .fleet-heading::before,
  .fleet-heading::after {
    top: 40%;
  }

  .fleet-heading::before {
    margin-left: -10vw;
  }

  .fleet-heading::after {
    margin-right: -10vw;
  }

  .fleet-heading-col {
    padding: 10px;
    align-items: center;
  }

  .fleet-heading h2 {
    font-size: 10vw;
  }

  .fleet-heading p {
    display: none;
  }

  /* Adjusting fleet home layout and carousel */
  .fleet-home {
    padding-left: 5vw;
    padding-top: 0px;
  }

  .item-fleet-home {
    margin-left: 5vw;
  }

  .carousel-image-fleet-home {
    max-height: 160px;
  }

  /* Positioning Owl Carousel navigation between category selector and fleet carousel */
  .unique-carousel-controls {
    position: relative;
  }

  .unique-carousel-controls .owl-theme .owl-nav {
    position: absolute; /* Keep it absolute for positioning */
    top: 20px; /* Position it just below the category selector */
    right: 4vw; /* Adjust to align the arrows to the right */
    width: auto; /* Auto width for better placement */
  }

  .unique-carousel-controls .owl-theme .owl-nav .owl-prev,
  .unique-carousel-controls .owl-theme .owl-nav .owl-next {
    position: relative; /* Keep the buttons relative */
    height: 30px;
    width: 30px;
    margin: 0 10px; /* Space between buttons */
  }

  /* Adjusting carousel categories for better responsiveness */
  .carousel-categories {
    margin-right: 0;
    flex-wrap: wrap; /* Allow items to wrap */
    justify-content: center; /* Center the items */
  }

  .carousel-categories li {
    margin: 15px; /* Adjust margin for each item */
  }

  .info-box {
    font-size: 12px; /* Adjust font size */
  }

  .carousel-image-fleet-home {
    object-fit: contain; /* Maintain aspect ratio */
  }
}

