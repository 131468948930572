.terms-conditions-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  .terms-conditions-container h1,   .terms-conditions-container h2 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
  }
  .bold-head{
    font-weight: bold;
  }
  
  .terms-conditions-container h1 {
    font-size: 28px;
    margin-bottom: 16px;
    text-align: center;
    color: #e63946; /* Primary color for headings */
  }
  
  .terms-conditions-container h2 {
    font-size: 22px;
    margin-top: 20px;
    color: #cf2e38; /* Subheading color */
  }
  
  .terms-conditions-container p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
  }
  
  .terms-conditions-container p {
    margin-bottom: 15px;
  }
  .terms-conditions-container a {
    color: #e63946;
    text-decoration: none;
  }
  
  .terms-conditions-container a:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 768px) {
    .terms-conditions-container {
      padding: 15px;
      margin: 15px;
    }
  
    .terms-conditions-container h1 {
      font-size: 24px;
    }
  
    .terms-conditions-container h2 {
      font-size: 20px;
    }
  
    .terms-conditions-container p {
      font-size: 14px;
    }
  }
