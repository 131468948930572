@import url("https://fonts.googleapis.com/css2?family=Allan&display=swap");

.hero-Section {
  overflow: hidden;
}

.sub-hero-section {
  position: relative; /* Relative positioning for the content */
  overflow: visible; /* Allow content to overflow */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: grid;
  grid-template-rows: auto auto 1fr;
  justify-content: center;
  align-items: start;
  gap: 20px;
  padding: 20px;
  font-family: "Alumni Sans", sans-serif;
  padding-bottom: 0;
  padding-top: 0;
}

.sub-hero-section::before {
  content: '';
  position: absolute;
  height: 60%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #141414; /* Background color */
  z-index: -1; /* Place it behind the content */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.row-1 h1 {
  text-align: center;
  color: white !important;
  font-size: 4.5em;
  font-weight: 50;
  margin: 0;
}

.row-1 p {
  text-align: center;
  color: white;
  margin: 0;
  font-size: 2em;
}

.row-2 {
  display: flex;
  justify-content: center;
}

.orangeText {
  color: #e63946;
  font-family: "Allan", sans-serif;
  font-size: 0.9em;
}

.btn-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
}

.row-3 {
  width: 100vw;
  overflow: hidden !important;

}

/* Example styles for positioning the arrows */
.slick-prev,
.slick-next {
  z-index: 1;
  top: 40% !important;
  transform: translate(0, -50%);
}

.slick-prev::before,
.slick-next::before {
  
    content: none !important;
} 
.slick-prev {
  left: 10vw !important;
}

.slick-next {
  right: 10vw !important;
}

.carousel-image {
  width: 30vw; /* Adjust the width as needed */
  height: 100%; /* Set a fixed height */
  object-fit: cover; /* This will cover the area, maintaining aspect ratio */
}
.item {
  display: flex !important;
  justify-content: center;
}



@media screen and (max-width: 1030px) {
  .hero-Section {
    height: auto;
  }

  .sub-hero-section{
    height: auto;
    padding-bottom: 0;
  }

  .sub-hero-section::before {
    height: auto; /* Remove max-height restriction on mobile */
  }


  .row-1 h1 {
    padding: 15px 0;
    font-size: 2.5em; /* Slightly reduce font size */
    margin-bottom: 20px; /* Add more space below the header */
  }

  .row-1 p {
    font-size: 1.5em; /* Adjust font size */
    margin-bottom: 20px; /* Add space below paragraph */
  }

  .row-2 {
    flex-direction: column; /* Stack buttons vertically */
    gap: 15px; /* Space between buttons */
  }

  .row-3 {
    height: 100%;
  }

  .carousel-image {
    width: 70vw; /* Adjust width of carousel images */
  }
  .slick-prev {
    left: 3vw !important;
  }
  
  .slick-next {
    right: 3vw !important;
  }
  .item {
    padding: 10px; /* Add padding around items */
  }


  .row.row-2 {
    display: flex;
    justify-content: center;
    width: 90vw; /* 90% of the viewport width */
    margin: 0 auto; /* Center the container */
    gap: 10px; /* Optional: Adds space between buttons */
  }
  

  .row.row-2 a {
    flex-grow: 1; /* Each link element grows to fill the space */
    text-align: center; /* Center the button text */
    display: flex; /* To make the link a flex container */
    justify-content: center; /* Center the button inside the link */
    text-decoration: none;
  }
  
  .row.row-2 button {
    width: 100%; /* Each button takes the full width of its parent link */
    margin-left: 0;
  }
  

}


.slick-track {
  display: flex !important;
  align-items: center !important;
}