@import url("https://fonts.googleapis.com/css2?family=Allan&display=swap");

.fleetHome-container {
  font-family: "Alumni Sans", sans-serif;
  width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 30px;
background-color: #141414;
  background-image: url('/public/images/wayPointers-min.png');
  background-size: contain; /* Ensure the image covers the whole container */
  background-position: center; /* Center the image in the container */
  background-repeat: no-repeat; 

}


.fleetHome-container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

/* Ensure content is above the overlay */
.fleetHome-container * {
  position: relative;
  z-index: 2;
}

.quote-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  max-width: 80%; /* Adjust as needed */
  position: relative; /* Needed for absolute positioning of pseudo-elements */
}

.quote-heading::before,
.quote-heading::after {
  content: "";
  position: absolute;
  top: 50%; /* Align vertically at the center of the div */
  width: 60%; /* Length of the line */
  height: 2px; /* Thickness of the line */
  background-color: #e63946; /* Color of the line */
}

.quote-heading::before {
  left: 0;
  margin-left: -26vw; /* Adjust based on the length and position of the line */
}

.quote-heading::after {
  right: 0;
  margin-right: -26vw; /* Adjust based on the length and position of the line */
}

.quote-heading h1 {
  font-size: 5vw;
  font-weight: 200;
  margin: 0 20px;
  color: #fff;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  position: relative;
  width: 60vw;
  margin-top: -1px;
  padding: 30px;
}

.fleet-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.fleet-form input,
.fleet-form select,
.fleet-form textarea,
.fleet-form button {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border: 3px solid #9f9f9f;
  border-radius: 10px;
  box-sizing: border-box;
}

#quote-btn {
  border: 2px solid #e63946; /* Red outline */
  background-color: transparent; /* No fill color */
  color: #e63946; /* Red text */
  padding: 10px 20px; /* Some padding for better appearance */
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.2s; /* Smooth transition for hover effect */
}

#quote-btn:hover {
  background-color: #e63946; /* Fill color on hover */
  color: white; /* White text on hover */
}

.fleet-form textarea,
.fleet-form button {
  grid-column: 1 / span 2;
}

.second-row {
  grid-column: 1 / span 2;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 6px;
}

.second-row > input {
  width: 100%;
}

.second-row-second {
  grid-column: 2 / span 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.fleet-form input[type="date"] {
  margin-left: 13px;
}

.fleet-form textarea {
  height: 150px;
}

/* Quote.css */

/* Custom class for Google Places Autocomplete dropdown */
.google-places-autocomplete-custom {
  z-index: 100;
}

.css-b62m3t-container {
  width: -webkit-fill-available;
}


.locations-input{
  z-index: 100;
}
.locations-input-2{
  z-index: 99;
}

.date-time-label {
  display: none; /* Hide labels by default */
  margin-bottom: 5px; /* Space between label and input */
}

@media (max-width: 768px) {
  .fleetHome-container {
    background-image: none;
  }
  .flex-container {
    flex-direction: column;
  }

  .form-container {
    width: 80vw;
  }

  .fleet-form {
    grid-template-columns: 1fr;
  }

  .second-row,
  .second-row-second {
    grid-column: 1;
    grid-template-columns: 1fr;
  }
  .fleet-form input[type="date"],
  .fleet-form input[type="time"] {
    margin-left: 0; /* Reset margin left */
    width: 100%; /* Ensure full width usage */
  }
  
  .fleet-form textarea,
  .fleet-form button {
    grid-column: 1;
  }

  .fleet-form input[type="date"] {
    margin-left: 0px;
  }

  .quote-heading::before {
    margin-left: -37vw; /* Adjust based on the length and position of the line */
  }

  .quote-heading::after {
    margin-right: -37vw; /* Adjust based on the length and position of the line */
  }

  .quote-heading h1 {
    font-size: 10vw;
  }

  .date-time-label {
    display: block; /* Show labels on mobile devices */
    color: #fff;
  }

  /* Style adjustments for mobile view */
  .date-time-container {
    display: flex;
    flex-direction: column;
    color: #fff;
  }



}

.input-with-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.input-with-icon i {
  position: absolute;
  margin: 10px 0 10px 10px;
  color: #aaa;
  z-index: 3;
}

.input-with-icon input {
  padding-left: 30px;
}

.form-container {
  max-height: 1000px; /* Set to a value higher than the form's actual height */
  transition: max-height 0.5s ease, padding 0.5s ease,
    background-color 0.5s ease;
  /* ... existing styles ... */
}
