@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

body{
  margin: 0;
  padding: 0;
}
:root {
  --primary-color: #000; /* Black */
  --secondary-color: #fff; /* White */
  --hover-color: rgba(0, 0, 0, 0.9); /* Slightly transparent black */
  --border-color: black;
  --hover-background-color: black;
  --hover-text-color: white;
}

.navbar {
  background-color: #ffffff;
  position: sticky; /* Ensures navbar is positioned relative to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* subtle shadow */

}
.nav-link, .nav-button {
  transition: transform 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.nav-link:hover, .nav-link:focus, .nav-button:hover, .nav-button:focus {
  transform: translateY(-2px); 
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  max-width: 1200px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}

.navbar-logo {
  color: #000;
  text-decoration: none;
  font-size: 1.5em;
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.navbar-logo img {
  max-height: 55px;
  max-width: 100%;
  height: auto;
}

/* Navigation Menu Styles */
.nav-items {
  display: flex;
  align-items: center;
}

.nav-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-link, .nav-button {
  text-decoration: none;
  color: black;
  padding: 10px 15px;
  margin: 0 5px;
  display: block;
}

.nav-link span {
  position: relative;
  display: inline-block;
}

.nav-link span::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: black;
  transition: width 0.3s ease-in-out;
}

.nav-link:hover span::after,
.nav-link:focus span::after {
  width: 100%;
}


.nav-button {
  background-color: transparent;
  color: black;
  border: 2px solid black;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 15px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-button:hover, .nav-button:focus {
  background-color: black;
  color: white;
}

/* Mobile Menu Icon Styles */
.menu-icon {
  display: none;
  cursor: pointer;
  z-index: 100;
  width: 40px; /* Added for consistency */
  height: 30px; /* Added for consistency */
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu-icon .menu-bar {
  width: 100%;
  height: 4px;
  background-color: black;
  margin: 5px 0;
  transition: 0.4s;
  border-radius: 150px;
}

.menu-icon.open .menu-bar:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}

.menu-icon.open .menu-bar:nth-child(2) {
  opacity: 0;
}

.menu-icon.open .menu-bar:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}

.menu-icon.open .menu-bar {
  background-color: white;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 20;
    padding-top: 60px;
    height: 100vh;
    overflow-y: auto;
    justify-content: center;
    text-align: center;
  }

  .navbar.open .nav-menu {
    display: flex;
  }

  .nav-link, .nav-button {
    display: block;
    color: white;
    padding: 20px;
    font-size: 1.8rem;
    width: 80%;
    margin: 10px auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  .nav-button {
    border: 2px solid white;
    font-weight: 700;
  }

  .nav-button:hover{
    background-color: white;
    color: #000;
  }

  .nav-menu li {
    width: 100%;
  }

  .menu-icon {
    display: block;
  }

  .overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: black;
    z-index: 10;
    transition: height 0.5s ease-in-out;
  }

  .overlay.open {
    display: block;
    height: 100%;
  }
}


/* ... existing styles ... */

/* Overlay Styles */
.overlay {
  display: block; /* Change this to block to allow transition */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0; /* Initially set height to 0 */
  background-color: rgba(0, 0, 0, 0.6); /* Use rgba for transparency */
  z-index: 10;
  opacity: 0; /* Initially transparent */
  visibility: hidden; /* Initially not visible */
  transition: opacity 0.5s ease, height 0s ease 0.5s, visibility 0s ease 0.5s; /* Transition for opacity and delay height/visibility change */
}

.overlay.open {
  height: 100%; /* Full height when open */
  opacity: 1; /* Fully visible when open */
  visibility: visible; /* Make it visible */
  transition: opacity 0.5s ease; /* Smooth transition for opacity */
}

/* ... remaining code ... */
