/* Privacy.css */

.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .privacy-policy-container h1 {
    color: #e63946;
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container h2 {
    color: #cf2e38;
    font-size: 1.8rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container p {
    font-size: 1rem;
    margin-bottom: 15px;
    text-align: justify;
  }
  
  .privacy-policy-container a {
    color: #e63946;
    text-decoration: none;
  }
  
  .privacy-policy-container a:hover {
    text-decoration: underline;
  }
  
  .bold-head {
    font-weight: bold;
  }
  