/* CallButton.css */
.call-button {
    position: fixed;
    bottom: 20px; 
    right: 20px;
    z-index: 1000; 
    border-radius: 50%; /* Makes the button round */
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    background-color: #e63946; /* Example color */
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px; /* Adjust icon size as needed */
  }
  
  .call-button:hover {
    background-color: #e63946; /* Darker shade on hover */
  }
  