@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.footer {
  display: grid;
  font-family: "Roboto", sans-serif;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 20px;
  background: #111111;
  color: #ffffff;
}

.footer-col {
  display: flex;
  flex-direction: column;
}

.footer-col-1 img {
  width: 40%; /* Adjust size as needed */
  height: auto;
  margin-bottom: 10px;
}

.footer-col-1 p {
  font-size: 0.9em;
  line-height: 1.5;
  margin-bottom: 20px;
  max-width: 95%;
}

.footer-col h3 {
  margin-bottom: 15px;
  text-decoration: underline;
  color: #e0e0e0;
  font-size: 1.1em; /* Slightly larger for headings */
  font-weight: 500; /* Medium font weight for headings */
}

.footer-col ul {
  list-style: none;
  padding: 0;
  margin-top: 0;
}

.footer-col ul li {
  margin-bottom: 5px;
}

.footer-col a {
  display: block;
  margin-bottom: 5px;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-col a:hover {
  color: #e63946; /* Adjust hover color as desired */
}

.footer-bar {
  background-color: #333333; /* Adjust the color as needed */
  color: #ffffff;
  text-align: center;
  padding: 10px 20px;
  font-size: 0.8em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.footer-bar a {
  color: #e63946; /* Adjust the color as needed */
  text-decoration: none;
}

.footer-bar a:hover {
  text-decoration: underline;
}

/* Existing styles */

.footer-col a {
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
  color: #ffffff;
  text-decoration: none;
  overflow: hidden; /* Ensures the underline doesn't exceed the text width */
}

.footer-col a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0; /* Adjust as needed to position the underline */
  left: -100%;
  background-color: #e63946; /* Adjust the color as needed */
  transition: left 0.3s ease-in-out;
}

.footer-col a:hover::after {
  left: 0;
}

.about-us-paragraph {
  font-size: 0.8em;
  line-height: 1.4;
  color: #ffffff;
  margin-top: 10px; /* Adjust as needed */
}

.about-us-paragraph a {
  color: #e63946; /* Adjust the color as needed */
  text-decoration: none;
  vertical-align: baseline; /* This ensures alignment with the rest of the text */
}

.about-us-paragraph a:hover {
  text-decoration: underline;
}

#ssl img {
  width: 10vw;
}
@media (max-width: 500px) {
  #ssl img {
    width: 20vw;
  }
}
.payment-icons {
  margin-bottom: 10px; /* Adjust as needed */
}

.payment-icons .fa-brands {
  font-size: 2em; /* Adjust icon size as needed */
  margin-right: 10px; /* Space between icons */
  color: #ffffff; /* Icon color */
}

@media (max-width: 768px) {
  .footer {
    grid-template-columns: 1fr; /* Changes to a single column layout */
    grid-template-rows: auto auto auto auto; /* Optional: Defines four rows explicitly */
  }

  /* Optional: Adjust other styles for smaller screens as needed */
  .footer-col-1 img,
  #ssl img {
    width: 40%; /* Adjust the image width as per your design */
  }
}

.footer {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 20px;
  background: #111111;
  color: #ffffff;
  position: relative; /* Required for absolute positioning of pseudo-element */
  overflow-x: hidden;
}

/* Pseudo-element for the tapered line */
.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%); /* Center the line */
  width: 110%; /* Adjust the width as per your design preference */
  height: 3px; /* Thickness of the line */
  background: linear-gradient(
    to right,
    transparent,
    #e63946,
    #e63946,
    transparent
  ); /* Creates the tapering effect */
  border-radius: 1px; /* Optional for slightly rounded edges */
}

/* ... rest of your styles ... */

@media (max-width: 768px) {
  .footer {
    grid-template-columns: 1fr;
    /* ... other adjustments ... */
  }

  .footer::before {
    width: 90%; /* You might want to adjust the line's width on smaller screens */
  }
}
.footer-col ul li a,
.about-us-paragraph,
.footer-bar {
  font-size: 0.9em; /* Adjusted font size for better readability */
  line-height: 1.6; /* Improved line spacing for readability */
}
