@import url("https://fonts.googleapis.com/css2?family=Allan&display=swap");
@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
  }

  .all{
    font-family: "Alumni Sans", sans-serif;

  }
.all::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: #141414;
  z-index: -1;
  border-radius: 0px 0px 0px 80px;
}


/* Existing styles ... */

.heading-title-reserve h1 {
  text-align: center;
  font-size: 3.5rem; /* Adjust size as needed */
  color: #cf2e38; /* Adjust color to fit the theme */
  margin-top: 1.5rem; /* Add top margin */
  margin-bottom: 1.5rem; /* Add bottom margin */
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* Optional: Adds depth to the text */
}
.white{
  color: rgb(238, 238, 238);

}
/* Responsive adjustments for the heading */
@media (max-width: 1155px) {
  .heading-title-reserve h1 {
    font-size: 2rem; /* Slightly smaller font on mobile */
  }
}

/* Continue with existing styles ... */


.reserve-container{
  background-color: white;
  padding: 2vw;
  padding-top: 1vh;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 85vw; /* Adjust as per your layout requirement */
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  margin-bottom: 15vh;
}

.reservation-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 30px;
    margin-left: auto; /* Center the form */
    margin-right: auto; /* Center the form */
}



.form-column {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-column input,
.form-column select {
    padding: 15px;
    border: 3px solid #ccc;
    border-radius: 20px;
    outline: 3px solid transparent;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, box-shadow 0.3s, outline 0.3s;
}




.css-13cymwt-control {
  padding: 5px;
  border-width: 3px !important;
  border-color: #ccc !important;
  border-radius: 20px !important;
  outline: 3px solid transparent;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s, outline 0.3s;
  padding-left: 10px;
}

.css-13cymwt-control:active, .css-13cymwt-control:focus, .css-13cymwt-control:hover, .form-column:hover input, .form-column:hover select {
  border-color: #cf2e38 !important;
  outline: 2px solid #cf2e38 !important;
  box-shadow: 0 0 12px 0 rgba(207, 46, 56, 0.4) !important;
}


.form-column input:focus,
.form-column select:focus {
    border-color: #cf2e38;
    outline: 2px solid #cf2e38;
    box-shadow: 0 0 12px 0 rgba(207, 46, 56, 0.4);
}




.toggle-button {
    display: flex;
    align-items: center;
    gap: 10px;
}

button[type="submit"] {
    grid-column: 1 / -1; /* Spans across all columns */
    padding: 15px 30px;
    font-size: 17px;
    width: 100%; /* Full width */
    border-radius: 20px;
    border: none;
    background-color: #e63946;
    color: white;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s, background-color 0.3s;
}

button[type="submit"]:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    background-color: #cf2e38;
}

/* Custom Checkbox (Toggle Button) Styles */
.toggle-buttons-container {
  display: flex;
  gap: 20px; /* Adjust as needed for spacing between toggles */
}

#toggle-btn {
  display: flex;
  justify-content: center;
  height: 100%;
}

.toggle-button {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between checkbox and label */
}

.toggle-input {
  display: none; /* Hide the default checkbox */
}

.toggle-label {
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: linear-gradient(to right, #bbb, #ddd); /* Subtle gradient */
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  border-radius: 100px;
  position: relative;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Soft shadow */
}

.toggle-label:after {
  content: '';
  position: absolute;
  top: 2.5px;
  left: 3px;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 90px;
  transition: left 0.3s ease, transform 0.3s ease, box-shadow 0.2s;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); /* Shadow for the toggle knob */
}

.toggle-input:checked + .toggle-label {
  background: linear-gradient(to right, #d63946, #e63946); /* Gradient for active state */
}

.toggle-input:checked + .toggle-label:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}

.toggle-label:hover {
  background: linear-gradient(to right, #aaa, #ccc); /* Slightly darker on hover */
}

/* Responsive Design Considerations */
@media (max-width: 1155px) {
  .reserve-container{
    margin-bottom: 5vh;
  }
  .toggle-buttons-container {
      gap: 10px; /* Smaller gap for smaller screens */
  }

  .toggle-label {
      width: 40px; /* Slightly smaller for mobile */
      height: 20px;
  }

  .toggle-label:after {
      width: 15px; /* Adjust knob size */
      height: 15px;
      top: 2.5px;
  }
}


/* Custom Radio Button Styles */

.radio-group-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 6px 0px 5px 0px;
}

@media (max-width: 1155px) {
    .radio-group-container {
        padding: 0px;
    }}

.radio-group-title {
    font-size: 16px; /* Adjust the font size as needed */
    font-weight: bold;
    margin-bottom: 5px;
}


.radio-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.radio-input {
    display: none; /* Hide the default radio button */
}

.radio-label {
    padding: 5px 10px;
    border: 2px solid #ccc;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    display: inline-block; /* Display inline with custom size */
}

.radio-input:checked + .radio-label {
    background-color: #e63946;
    border-color: #e63946;
    color: white;
}



@media (max-width: 1155px) {
    .reservation-form {
        grid-template-columns: 1fr;
    }

}


.row-four-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates four columns */
    gap: 20px;
}

.row-four-columns .column input {
  width: -webkit-fill-available;
    padding: 15px;
    padding-left: 30px;
    border: 3px solid #ccc;
    border-radius: 20px;
    outline: 3px solid transparent;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, box-shadow 0.3s, outline 0.3s;
}

.row-four-columns .column input:focus {
    border-color: #cf2e38;
    outline: 2px solid #cf2e38;
    box-shadow: 0 0 12px 0 rgba(207, 46, 56, 0.4);
}

@media (max-width: 1155px) {
    .row-four-columns {
        grid-template-columns: 1fr;/* Two columns for smaller screens */
    }

}


.form-header {
    text-align: center;
    margin-bottom: 30px; /* Increased spacing */
  }
  
  .form-header h2 {
    font-size: 40px;
    font-weight: bold;
    color: #424242;
    margin-bottom: 0px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow */
  }

  .form-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .form-header h2 {
    margin: 0 10px; /* Adjust spacing around the text as needed */
    padding: 50px 20px 20px 20px;
  }
  
  .form-header::before,
  .form-header::after {
    content: '';
    flex: 1; /* This allows the lines to expand to fill the available space */
    height: 2px; /* Adjust the height for thicker or thinner lines */
    background: #e6e6e6; /* Adjust the color as needed */
    margin-top: 30px;
  }
  
  
  .progress-bar {
    display: flex;
    justify-content: space-between; /* Distributes space evenly */
    align-items: center;
    position: relative;
    padding: 0 15px; /* Padding to prevent content clipping */
    max-width: 400px; /* Max width for better alignment */
    margin: 0 auto; /* Center align */
  }
  
  .step {
    text-align: center;
    position: relative;
    width: 45px;
  }
  
  .bullet {
    width: 35px; /* Slightly larger bullet size */
    height: 35px;
    border-radius: 50%;
    border: 2px solid #333;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Soft shadow for depth */
  }
  
  .bullet.active {
    background-color: #e63946;
    color: white;
    border-color: #e63946; /* Same color as the background */
  }
  
  .step p {
    margin-top: 20px; /* Adjusted spacing for label below the bullet */
    font-size: 14px; /* Adjust font size */
    color: #666; /* Slightly muted text color */
  }
  
  .step.active p {
    font-weight: bold;
    color: #333; /* Darker color for active step */
  }
  
  /* Line connecting bullets */
  .progress-bar::before {
    content: '';
    position: absolute;
    height: 2px;
    width: calc(100% - 80px); /* Width adjusted for larger bullets */
    background-color: #ccc;
    top: 17.5px; /* Align with the center of bullets */
    left: 35px; /* Start from the center of the first bullet */
  }
  
  /* ... */
  .date-time-label-reserve{
    display: none;
  }
  @media (max-width: 1155px) {
    /* Adjustments for responsive design */
    .progress-bar {
      width: calc(100% - 80px)
    }
  
    .progress-bar::before {
      width: 80%;
      height: 5px; /* Adjust for vertical line */
      top: 19px; /* Adjust for vertical alignment */

    }
  
    .step p {
      margin-top: 10px; /* Adjust spacing for mobile */
    }
    .date-time-label-reserve{
      display: block;
      color: #333;
      align-self: baseline;
    }
    .row-four-columns {
      grid-template-columns: 1fr; /* Change to single column layout */
      width: 100%; /* Ensure it takes the full width of the container */
  }

  .row-four-columns .column input {
      width: 90%; /* Make each input take full width */
      padding: 15px; /* Adjust padding as needed */
      /* Remove padding-left if it was previously set */
  }

  .remove-icon i {
    display: none; /* Hide the icon */
}

  }
  

  
  
  .icon i {
    position: absolute;
    margin: 16px 0 0 10px;
    color: #aaa;
    z-index: 3;
  }
  
  .icon input{
    padding-left: 30px;
  }
  .icon select{
    padding-left: 30px;
  }

  
/* ... existing styles ... */

.number-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }
  
  .number-input .minus, .number-input .plus {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    user-select: none;
    margin: 0 10px;
  }
  
  .number-input input[type='number']::-webkit-inner-spin-button,
  .number-input input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .number-input input[type='number'] {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  .number-input i {
    margin: -2px 0 0 58px;
  }
  
  /* ... additional styles ... */
    

  .number-input-horizontal {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: row;
  }
  
  .number-input-horizontal .minus-horizontal, 
  .number-input-horizontal .plus-horizontal {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    user-select: none;
  }
  
  .number-input-horizontal input[type='number'] {
    text-align: center;
    flex-grow: 1; /* Adjust input field width */
    border: 3px solid #ccc;
    border-radius: 20px;
    padding: 15px;
    margin: 0 5px; /* Spacing between buttons and input */
  }
  
  .number-input-horizontal i {
    position: absolute;
    margin: 0px 0px 0 65px;
    color: #aaa;
    z-index: 3;
  }
  
  /* Hide default spin buttons */
  .number-input-horizontal input[type='number']::-webkit-inner-spin-button,
  .number-input-horizontal input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  

/* ... existing styles ... */
/* ... existing styles ... */

.stop-input-row {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}

.stop-input-row i {
  margin-top: 0px;
}

.stop-input-row input {
  flex-grow: 1; /* This will make the input field grow to fill the available space */
  max-width: calc(100% - 60px); /* Adjust this value as needed */
}

.stop-remove-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #e63946; /* Example color */
  margin-left: 10px;
}

.stop-remove-button i {
  font-size: 25px; /* Adjust the size as needed */
  margin: -11px 0px 0px -15px;
}

.stop-add-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #e63946; /* Example color */
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 17px;
}

.stop-add-button i {
  font-size: 20px; /* Adjust the size as needed */
  line-height: 1; /* Adjust line height for vertical alignment */
  margin-top: -2px; /* Fine-tune vertical alignment */
}


.full-width-row {
  grid-column: 1 / -1; /* Spans from the first to the last column */
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 20px; /* Adjust gap as needed */
}

@media (max-width: 1155px) {
  .full-width-row {
    grid-template-columns: 1fr; /* One column for smaller screens */
  }

  .number-input input{
    width: 100%;
  }
}

.creditCard input{
  margin-bottom: 10px;
}



.credit-card-form {
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
}

.form-column.creditCard {
  margin-bottom: 15px;
}



.agreement-section {
  margin-top: 20px;
}

.agreement-checkbox {
  display: flex;
  align-items: center;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid #ddd;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.agreement-checkbox input[type="checkbox"] {
  display: none;
}

.agreement-checkbox input[type="checkbox"]:checked + .custom-checkbox {
  background-color: #e63946;
  border-color: #e63946;
}

.agreement-checkbox a {
  color: #e63946;
  text-decoration: none;
}

.agreement-checkbox a:hover {
  text-decoration: underline;
}

.payment-info, .cancellation-policy {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.payment-info p, .cancellation-policy p {
  margin: 0;
  font-size: 14px;
}


.form-column.stop-input-row.icon {
  width: 100%; /* Set the width to 100% of the parent container */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Space out the child elements */
}

.form-column.stop-input-row.icon .fa-map-marker-alt {
  margin-right: 10px; /* Add some margin to the icon for spacing */
}

/* Style for GooglePlacesAutocomplete */
.form-column.stop-input-row.icon .GooglePlacesAutocomplete__input {
  flex-grow: 1; /* Let the input grow to fill available space */
  margin-right: 10px; /* Add some margin to the right of the input */
}

/* Style for the remove button */
.form-column.stop-input-row.icon .stop-remove-button {
  margin-left: 10px; /* Add some margin to the left of the button */
}



.additional-info {
  background-color: #f9f9f9; /* Light grey background */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  margin: 20px auto;
  max-width: 75vw; /* Narrower width for a more compact layout */
  border: 1px solid #ddd; /* Subtle border */
}

.additional-info h2 {
  text-align: center;
  color: #cf2e38;
  margin-bottom: 20px;
  font-size: 22px; /* Slightly smaller font size for headings */
}

.additional-info div {
  margin-bottom: 15px;
}

.additional-info h3 {
  color: #424242;
  margin-bottom: 10px;
  font-size: 17px; /* Smaller font size for sub-headings */
  font-weight: 600; /* Slightly bolder for headings */
}

.additional-info p {
  color: #333;
  margin-left: 15px;
  font-size: 15px; /* Smaller font size for content */
  line-height: 1.4; /* Tighter line spacing */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .additional-info {
    padding: 15px;
    margin: 15px;
    max-width: 90vw; /* Adjust width for smaller screens */
  }

  .additional-info h2 {
    font-size: 20px;
  }

  .additional-info h3 {
    font-size: 16px;
  }

  .additional-info p {
    margin-left: 10px;
    font-size: 14px;
  }
}
