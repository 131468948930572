/* Base styles for both buttons */
.btn-outline, .btn-fill {
    padding: 12px 24px; /* Slightly larger padding */
    font-size: 0.9rem; /* Slightly larger font size */
    border-radius: 5px; /* Rounded corners */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
    cursor: pointer;
    margin-left: 10px;
    position: relative; /* Needed for overlay */
  }
  
  /* Styles for the outline button */
  .btn-outline {
    border: 2px solid #E63946; /* Example border color */
    background-color: transparent;
    color: #E63946; /* Example text color */
  }
  
  /* Styles for the fill button */
  .btn-fill {
    border: 2px solid #E63946; /* Same border color for consistency */
    background-color: #E63946; /* Example fill color */
    color: white; /* Text color */
  }
  
  /* Hover effect for both buttons - Light grey overlay */
  .btn-outline:hover::after, .btn-fill:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(211, 211, 211, 0.5); /* Light grey with low opacity */
    border-radius: 3px; /* Match the border radius of the button */
  }