@import url("https://fonts.googleapis.com/css2?family=Allan&display=swap");

.services-container {
  font-family: "Alumni Sans", sans-serif;
  color: white;
  display: flex;
  justify-content: center;
}

.service-box {
  background-color: #141414;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create 3 columns */
  gap: 10px; /* Adjust the gap between columns as needed */
  overflow: hidden;
  padding: 5vh 10vw;
}
/* OurServices.css */

.owl-nav .owl-prev, .owl-nav .owl-next {
  margin-top: 25px;
}

.srv-row {
  /* ... existing styles ... */
  height: 100%; /* Set height to fill the column */
  overflow: hidden; /* Add this to prevent any overflow */
}
.srv-column {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust the gap between rows as needed */
  align-items: stretch;
  justify-content: center;
}

.srv-row h2 {
  font-weight: 100;
  font-size: 4.5vw;
  margin: 0;
  padding-left: 10px;
}
.srv-row p {
  padding-left: 20px;
  font-size: 1.2vw;

}
.intro-text {
  margin-left: 0; /* Remove left margin of the paragraph */
  position: relative; /* Needed for absolute positioning of the pseudo-element */
  margin-top: 5px;
}

.intro-text::before {
  content: ""; /* Required for pseudo-elements */
  position: absolute;
  left: 0; /* Align with the left edge of the paragraph */
  top: 0; /* Position at the top of the paragraph */
  height: 1px; /* Height of the line */
  background-color: white; /* Color of the line */
  width: 100%; /* Width relative to the parent element */
  margin-bottom: 10px; /* Space between the line and the text */
}


/* Target the images in the first column */
.service-box > .srv-column:nth-child(1) {
  display: flex;
  flex-direction: column;
}

.service-box > .srv-column:nth-child(1) .srv-row:first-child {
  flex: 1; /* Make the first row smaller */
}

.service-box > .srv-column:nth-child(1) .srv-row:last-child {
  flex: 2; /* Make the second row larger */

}

/* Ensure images cover their area */
.service-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2vw;
  cursor: pointer;
}

.img-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.img-text {
  position: absolute;
  bottom: -1px;
  left: -1px;
  background-color: #141414;
  color: white;
  padding: .7vw;
  font-size: 1.2vw; /* Adjust the font size as needed */
  border-radius: 0 1vw 0 2vw; /* Rounded top right corner */
}

.service-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2vw;
}

/* Target the images in the second column */
.service-box > .srv-column:nth-child(2) > img {
  width: 26vw;
  height: auto;
  object-fit: cover;
}





.why-us {
  font-family: "Alumni Sans", sans-serif;;
  display: flex;
  justify-content: space-around;
  background-color: #F2F4F6;
  padding: 80px 20px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.icon-row {
  display: flex;
  justify-content: center;
  width: 100%;
}
.icon-row img {
  max-width: 100px;
  height: auto;
}

.text-row {
  text-align: center;
  font-family: "Alumni Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  margin-top: 20px;
  max-width: 80%;
}

.mobile-serive{
  font-size: 5vw;
  border-top-right-radius: 2vw;
  padding: 2vw;
}

@media (max-width: 768px) {
  .column {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .text-row {
    font-size: 1rem;
  }

  .column {
    width: 100%;
  }
}


@media (max-width: 768px) {
  .desktop-ourService{
    display: none;
  }
  .service-box {
    grid-template-columns: 1fr; /* Stack columns vertically on small screens */
    padding: 2vh 5vw 5vh 5vw; /* Reduce padding for smaller screens */
  }

  .srv-row h2 {
    font-size: 10vw; /* Increase font size for better readability */
  }

  .srv-row p {
    font-size: 4vw; /* Adjust text size for smaller screens */
  }

  .service-box img {
    width: 100%;
    height: auto; /* Adjust image height for smaller screens */
    border-radius: 5vw; /* Adjust border-radius for smaller screens */
  }

  .img-container .img-text {
    font-size: 3vw; /* Adjust font size for captions */
    padding: 2vw; /* Adjust padding for smaller screens */
  }

  .why-us {
    flex-direction: column; /* Stack elements vertically */
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .column {
    width: 100%; /* Full width for each column */
    margin-bottom: 15px; /* Add margin between columns */
  }

  .icon-row img {
    max-width: 70px; /* Adjust icon size for smaller screens */
  }

  .text-row {
    font-size: 0.9rem; /* Decrease font size for text */
  }
}



.owl-theme .owl-nav [class*='owl-']{
  font-size: 17px !important;
}


.owl-prev span, .owl-next span {
  display: none;
}

.service-carousel .owl-nav [class*='owl-']{
  margin-top: 50px;
}

.why-us-carousel .owl-nav [class*='owl-']{
  color: #000  !important;
  background: none !important;
  border-radius: 100px !important;
  z-index: 100;
  margin-top: 50px;
}

/* Styles for the small screen carousel */
.small-screen-carousel .item img {
  width: 80vw; /* Set the width of images to 80vw */
  height: auto; /* Maintain the aspect ratio */
  margin: 0 auto; /* Center the images */
}

/* Ensure the carousel container doesn't exceed the viewport width */
.small-screen-carousel {
  max-width: 100vw;
  margin: auto;
  overflow: hidden; /* Prevent horizontal scrolling */
}
.service-carousel{
  width: 90vw !important;
}

/* Adjust the paragraph styling in the 'Our Services' section for small screens */
@media (max-width: 768px) {
  .srv-row p {
    white-space: normal; /* Ensure text wraps */
    word-wrap: break-word; /* Break words to prevent overflow */
    font-size: 3.5vw; /* Adjust font size for better readability */
  }

  .desktop-ourService {
    display: none; /* Hide desktop specific services */
  }

  /* Additional responsive adjustments */
  .service-box {
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
  }

  .service-box > .srv-column:nth-child(1) .srv-row:first-child,
  .service-box > .srv-column:nth-child(1) .srv-row:last-child {
    flex: none; /* Adjust the flex property for the rows */
  }
}

/* You may want to adjust the Owl Carousel navigation buttons for small screens */
.owl-theme .owl-nav [class*='owl-'] {
  font-size: 14px; /* Smaller buttons */
}

