@import url("https://fonts.googleapis.com/css2?family=Allan&display=swap");
#contact-all{
  height: 100%;
  font-family: "Alumni Sans", sans-serif;
}
.Contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  padding: 20px;
  
}

.Contact h1{
  font-size: 3rem;
  margin: 0;
}

.Contact p {
  margin-top: 0;
  font-size: 1.5rem;
  color: #cf2e38;
}

.Contact::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: #141414;
  background-blend-mode: normal, lighten;
  z-index: -1;
  border-radius: 0px 0px 0px 80px;
}

.Contact-form {
  background-color: white;
  padding: 2vw;
  padding-top: 1vh;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 75%; /* Adjust as per your layout requirement */
  max-width: 75vw; /* Adjust as per your layout requirement */
  margin-left: auto;
  margin-right: auto;
}

.Contact-form h2 {
  font-size: 2rem;
  color: #333;
  text-align: center;
}

.Contact-form input,
.Contact-form textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none; /* Removes the standard border */
  border-bottom: 1px solid #ddd; /* Adds a bottom border */
  box-sizing: border-box; /* Ensures padding doesn't affect the overall width */
}
.Contact-form textarea {
  width: 100%;
  height: 100px; /* Adjust this value as needed */
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 0; /* Optional: removes border-radius for a more uniform look */
}

.Contact-form button {
  width: 20%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(to bottom right, #ff6f71, #e63946), 
              linear-gradient(to right, rgba(255, 255, 255, 0.4), transparent);
  color: white;
  cursor: pointer;
}

#contact-btn{
  width: 20%;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form-row input[type="text"],
.form-row input[type="email"],
.form-row input[type="tel"] {
  flex: 1;
  margin-right: 10px; /* Spacing between fields */
}

.form-row input[type="text"]:last-child,
.form-row input[type="email"]:last-child,
.form-row input[type="tel"]:last-child {
  margin-right: 0; /* Remove margin for the last element */
}

.form-row textarea {
  width: 100%;
}
.heading-with-lines {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.heading-with-lines h2 {
  padding: 0 20px;
  color: #333;
}

.heading-with-lines::before,
.heading-with-lines::after {
  content: '';
  flex: 1;
  height: 2px;
  background: #ddd;
  margin: 0 10px;
}



/* Responsive Styles */
@media (max-width: 768px) {
  #contact-all {
    height: auto;
    font-size: 14px;
  }

  .Contact {
    padding: 10px;
  }

  .Contact-form {
    width: 80%;
    max-width: none;
    padding: 5vw;
  }

  .form-row {
    flex-direction: column;
  }

  .form-row input[type="text"],
  .form-row input[type="email"],
  .form-row input[type="tel"] {
    margin-right: 0;
    margin-bottom: 10px;
  }

  #contact-btn {
    width: 100%;
    padding: 15px;
  }
}