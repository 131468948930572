@import url("https://fonts.googleapis.com/css2?family=Allan&display=swap");

.fleet {
  font-family: "Alumni Sans", sans-serif;
  overflow: hidden;
}

.fleet-heading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  max-width: 80%; /* Adjust as needed */
  position: relative; /* Needed for absolute positioning of pseudo-elements */
}

.fleet-heading-page h1 {
  font-size: 7vw; /* Large font size for larger screens */
  font-weight: 300;
  margin: 0; /* Remove default margin */
}

.fleet-heading-page::before,
.fleet-heading-page::after {
  content: '';
  position: absolute;
  top: 50%; /* Align vertically at the center of the div */
  width: 50%; /* Length of the line */
  height: 2px; /* Thickness of the line */
  background-color: #333; /* Color of the line */
}

.fleet-heading-page::before {
  left: 0;
  margin-left: -20%; /* Adjust based on the length and position of the line */
}

.fleet-heading-page::after {
  right: 0;
  margin-right: -20%; /* Adjust based on the length and position of the line */
}

.item-fleet-page{
 margin: 1vh 2.5vw;
}
.fleet-items-top-page{
  padding-bottom: 6vh;}
/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .fleet-heading-page h1 {
    font-size: 9vw; /* Smaller font size for mobile screens */
  }

  .fleet-heading-page::before,
  .fleet-heading-page::after {
    width: 40%; /* Shorter lines for smaller screens */
    margin-left: -10%; /* Adjust the position for mobile */
    margin-right: -10%;
  }
}
/* Existing CSS */

/* ... Your existing styles ... */

/* Media Queries for smaller mobile devices */
@media (max-width: 500px) {
    .fleet-heading-page h1 {
      font-size: 10vw; /* Even smaller font size for very small screens */
      padding: 0 10px; /* Add padding to prevent text from touching screen edges */
    }
  
    .fleet-heading-page::before,
    .fleet-heading-page::after {
      width: 30%; /* Further shorten the lines for smaller screens */
      margin-left: -5%; /* Adjust the position for very small screens */
      margin-right: -5%;
    }
  }
  
  .fleet-items-top-page{
    display: flex;
    justify-content: center;
  }
  .fleet-items-container-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  
  .item-fleet-page-all{
    flex-basis: calc(33.333% - 10px); /* Adjust the percentage and margin for spacing */
    margin: 5px; /* Adjust as needed */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }

  .item-fleet-page{
    padding-left: 0;
    cursor: pointer;
    transition: all 0.3s ease; 
  }

  .item-fleet-page:hover {
    transform: scale(1.05); /* Slightly increase size */
  }
  
  .card-fleet-page {
    background-color: #E5EAEC;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .fleet-items-container-page a {
    text-decoration: none; /* Removes the underline from links */
    color: #0c0c0c; /* Text color */
  }
  
  

  .card-fleet-page h4{
    margin: 10px 0 5px 0;
  }
  .card-fleet-page img{
    width: 80%;
  }

  .info-box-page{
    display: inline-flex;
    align-items: center;
    background-color: #E5EAEC;
    border-radius: 10px;
    padding: 5px 10px;
    margin-right: 10px;
    font-size: 14px;
  }

  .info-box-page span{
    margin-left: 5px;
  }
  /* Responsive adjustments (optional) */
  @media (max-width: 768px) {
    .item-fleet-page-all {
      flex-basis: calc(50% - 10px); /* Two columns on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .item-fleet-page-all {
      flex-basis: 100%; /* One column on very small screens */
    }
  }
  

  
.fleet-cat-selector {
  display: flex;
  justify-content: center;
  padding: 0 50px 50px 50px;
}

.fleet-categories {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.fleet-categories li {
  margin: 20px; 
  border-right: 1px solid lightgrey; /* Light grey line between items */
  padding-right: 20px; /* Padding to the right of the text */
}

.fleet-categories li:last-child {
  border-right: none; /* No border for the last item */
}

.fleet-categories a {
  text-decoration: none;
  color: #333; /* Link color */
  font-weight: bold;
  border-radius: 10px;
  padding: 5px 10px; /* Padding for better click area */
}

.fleet-categories a:hover,
.fleet-categories a:active,
.fleet-categories a.selected { /* Add 'selected' class for the active item */
  background-color: #e63946;

  color: white;
}
