/* Import a custom font - example with Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

@keyframes moveBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(270deg, #fff, #f8f8f8, #fff, #f8f8f8);
  background-size: 200% 200%;
  animation: moveBackground 30s ease infinite;
}

/* Styling for the grid layout */
.grid-layout {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 30px;
  padding: 20px;
  max-width: 80vw;
  margin: auto;
  box-sizing: border-box;
  overflow: auto;
}

/* Styling for the first child (image gallery) */
.grid-layout > div:first-child {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

/* Styling for the second child (details and buttons) */
.grid-layout > div:last-child {
  background-color: #f7f7f7;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Typography */
.grid-layout h1 {
  color: #333;
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 700;
}

.grid-layout ul {
  list-style: none;
  padding: 0;
  font-size: 16px;
}

.grid-layout li {
  margin-bottom: 10px;
  color: #555;
}

/* Styling for buttons */
.quote-btn, .reserve-btn {
  display: block;
  width: 100%;
  padding: 10px 15px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.quote-btn {
  background-color: transparent;
  border: 2px solid #e63946;
  color: #e63946;
}

.quote-btn:hover {
  background-color: rgba(211, 211, 211, 0.8);
  transform: scale(1.05);
}

.reserve-btn {
  background-color: #e63946;
  border: 2px solid #e63946;
  color: white;
}

.reserve-btn:hover {
  background-color: #f0757a;
  transform: scale(1.05);
}

.grid-layout a {
  text-decoration: none;
}

/* Image Gallery Customizations */
.image-gallery-slide .image-gallery-image {
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
}

/* Adjustments for Responsive Design */
@media (max-width: 1000px) {
  .grid-layout {
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 10px;
    max-width: 100vw;
  }
  .image-gallery-fullscreen-button{
    display: none !important;
  }

}

  .image-gallery-slide .image-gallery-image{
    width: 80% !important;
  }
  /* Hover effect for left and right navigation arrows */
.image-gallery-left-nav:hover, .image-gallery-right-nav:hover,
.image-gallery-left-nav:hover .image-gallery-icon, .image-gallery-right-nav:hover .image-gallery-icon {
  color: #e63946 !important; /* Changes the arrow color on hover */
}

/* Hover effect for thumbnail selectors */
.image-gallery-thumbnails .image-gallery-thumbnail:hover,
.image-gallery-thumbnails .image-gallery-thumbnail.active,
.image-gallery-thumbnails .image-gallery-thumbnail:focus {
  border-color: #e63946 !important; /* Changes the border color of thumbnails on hover */
}

/* If there are other buttons or selectable elements, apply similar styles */
.image-gallery-custom-action:hover {
  background-color: #e63946 !important; /* Example for custom action buttons */
}

/* Hover effect for play button */
.image-gallery-play-button:hover, .image-gallery-play-button:hover .image-gallery-icon {
  color: #e63946 !important; /* Changes the play button color on hover */
}

/* Hover effect for fullscreen (maximize/minimize) button */
.image-gallery-fullscreen-button:hover, .image-gallery-fullscreen-button:hover .image-gallery-icon {
  color: #e63946 !important; /* Changes the fullscreen button color on hover */
}




/* Typography Enhancements */
.vehicle-details {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.vehicle-details h1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #1a202c;
}

.vehicle-details ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.vehicle-details li {
  font-size: 16px;
  padding: 8px 0;
  border-bottom: 1px solid #e2e8f0;
}

.vehicle-details li:last-child {
  border-bottom: none;
}

/* Button Styling */
.vehicle-details .quote-btn, .vehicle-details .reserve-btn {
  padding: 12px 20px;
  margin-top: 15px;
  font-size: 16px;
  text-align: center;
}

.vehicle-details .quote-btn:hover, .vehicle-details .reserve-btn:hover {
  opacity: 0.9;
}

/* Link Styling */
.vehicle-details a {
  text-decoration: none;
  color: inherit;
}

/* Responsive Design Adjustments */
@media (max-width: 1000px) {
  .vehicle-details {
    padding: 15px;
    margin-top: 10px;
  }

  .vehicle-details h1 {
    font-size: 24px;
  }

  .vehicle-details li {
    font-size: 14px;
  }

  .vehicle-details .quote-btn, .vehicle-details .reserve-btn {
    padding: 10px 15px;
    font-size: 14px;
  }
}


